(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.home')
      .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl($state, $rootScope, authManager, AuthenticationService, userResolve, User) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';
    // TODO: Not works
    vm.authManager = authManager;
    vm.logout = logout;
    vm.userEmail = userResolve.email;

    // TODO: Revisar
    $rootScope.$on('$stateChangeStart', function (event, toState) {
      if (!_.isUndefined(toState.url) && toState.url.startsWith('/home')) {
        User.get({}, {}, function (data) {
          vm.userEmail = data.email;
        }, function () {
          $state.go('authentication.login');
        });
      }
    });


    function logout() {
      AuthenticationService.logout();
      $state.go('authentication.login');
    }
  }
}());
